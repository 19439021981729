import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout' 
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"

const ArticleTemplate = ({ data }) => (
  <Layout>
    <Seo title={data.strapiArticle.title} />
    <h1>{data.strapiArticle.title}</h1>
    {/* <p>by <Link to={`/authors/User_${data.strapiArticle.author.id}`}>{data.strapiArticle.author.username}</Link></p> */}
    <ReactMarkdown>{data.strapiArticle.content}</ReactMarkdown>
  </Layout>
)

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($slug: String!) {
    strapiArticle(slug: {eq: $slug}) {
      title
      content
      slug
    }
  }
`